@import './antd/layout';
@import './antd/menu';
@import './antd/table';
@import './antd/card';
@import './antd/input';

body {
  overflow-x: hidden;
}

.v-align-bottom {
  vertical-align: text-bottom !important;
}

.card-main {
  th.ant-table-cell {
    background: #fff !important;
    color: #434348;
    font-size: 16px;
    font-weight: 400;
  }
}

ul.ant-menu-inline-collapsed.ant-menu-vertical,
ul.ant-menu-inline.ant-menu {
  min-height: 100vh !important;
  height: 100%;
  background: #111 !important;
}

aside.ant-layout-sider {
  overflow-y: hidden;
  height: 100%;
  background: #111 !important;
}

.ant-ribbon-text {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 0px;
}

.ant-badge {
  svg {
    color: white;
    font-size: 18px;
    vertical-align: middle;
  }
}

.ant-tabs-right > .ant-tabs-content-holder,
.ant-tabs-right > div > .ant-tabs-content-holder {
  order: 0;
  margin-right: -1px;
  border-right: 1px solid #333333;
}
.ant-tabs > .ant-tabs-nav,
.ant-tabs > div > .ant-tabs-nav {
  min-width: 20%;
  margin: 20px 20px 20px 0;
}

.ant-badge-status-dot {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border-radius: 50%;
}

.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content {
  border-right: 1px dashed #f0f0f0;
}

[data-theme='compact']
  .site-collapse-custom-collapse
  .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  margin-bottom: 24px;
  overflow: hidden;
  background: #f7f7f7;
  border: 0px;
  border-radius: 2px;
}

.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label,
.ant-descriptions-bordered.ant-descriptions-small
  .ant-descriptions-item-content {
  background: white;
}

.ant-collapse-borderless {
  background-color: #e9e9e9;
  border: 0;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  background: #f7f7f7;
  font-weight: bold;
  font-family: 'Montserrat';
  letter-spacing: 1px;
}

.ant-descriptions-bordered .ant-descriptions-view {
  border: 1px solid #d9d9d9;
}

.ant-comment-content-author-name > *:hover {
  color: black;
}

.ant-progress-success-bg,
.ant-progress-bg {
  position: relative;
  background-color: #f5f5f5;
  border-radius: 100px;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}

.ant-list-lg .ant-list-item {
  @media (max-width: 991px) {
    padding: 16px 12px;
  }
}

.ant-modal-confirm-btns {
  button {
    margin-bottom: 15px;
  }
}

.ant-descriptions-item-label {
  font-size: 12px !important;
}

.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label,
.ant-descriptions-bordered.ant-descriptions-small
  .ant-descriptions-item-content {
  font-size: 13px;
  .ant-badge-status-text {
    font-size: 12px;
  }
}

.ant-popover-buttons {
  display: flex;
}

.oc-description {
  .ant-descriptions-header {
    margin-bottom: 10px;
  }
}

.text-success {
  color: #4caf50 !important;
}
