li.ant-menu-item {
  a {
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  svg {
    margin-right: 6px;
    width: 18px;
  }
}

.ant-drawer-header-no-title {
  button {
    color: #d6f1ff;
  }
}

.ant-drawer-body {
  padding: 15px 0 0 0;
  background: #111 !important;

  img {
    width: 20%;
  }
}

li.ant-menu-item svg {
  margin-right: 0px !important;
  width: 22px !important;
  font-size: 22px !important;
}

.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
.ant-menu-dark .ant-menu-item-selected .anticon + span {
  color: var(--light-color);
  font-family: 'Montserrat';

  svg {
    color: var(--light-color);
  }
}

.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content,
.ant-menu-inline.ant-menu-root
  .ant-menu-submenu-title
  > .ant-menu-title-content {
  font-family: 'Montserrat';
}

.ant-drawer-body.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-dark .ant-menu-item-selected .anticon {
  color: var(--light-color) !important;
}

li.ant-menu-item {
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.05) !important;
  font-size: 13px;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: rgb(173 226 255 / 15%) !important;
  border-radius: 0px 5px 5px 0px;
  border-left: 2px solid #ade2ff;
  font-size: 13px;
  font-weight: 600;

  svg {
    margin-left: -2px !important;
  }
}

.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-dark .ant-menu-item-selected .anticon {
  color: var(--light-color) !important;
}

.ant-menu-item svg {
  vertical-align: middle;
}

.ant-drawer-close {
  color: rgb(255 255 255 / 45%);
}

.ant-drawer-header {
  background: #111;
}

.ant-drawer-content-wrapper {
  max-width: 270px;
}

.ant-menu-inline-collapsed {
  li.ant-menu-item {
    padding: 0 calc(50% - 20px / 2) !important;
  }

  svg {
    width: 20px !important;
  }
}
