.ant-btn-primary {
  color: white;
  background: linear-gradient(90deg, #4074b4, #56b38c) !important;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  border-radius: 0;
  font-weight: 500;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus,
.ant-btn-primary:active {
  opacity: 0.9;
  border: 0;
  box-shadow: 1px 1px 22px 2px rgb(17 17 17 / 20%);
}

.ant-btn-ghost {
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  border-radius: 0;
  font-weight: 600;
  letter-spacing: 1px;
  transition: all 0.3s ease;

  color: #191919;
  background: transparent;
  border: 1px solid #daaf7a;
}

.ant-btn-ghost:hover,
.ant-btn-ghost:focus,
.ant-btn-ghost:active {
  color: #191919;
  background: #daaf7a;
  border: 1px solid #daaf7a;

  &::before {
    width: 0;
  }

  &::after {
    height: 0;
  }
}

.ant-btn-ghost {
  &::before {
    content: '';
    display: flex;
    position: absolute;
    background: #444;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    width: calc(100% + 2px);
    height: 1px;
    bottom: -6px;
    left: 10px;
    top: inherit;
    right: inherit;
    opacity: 0.6;
  }

  &::after {
    content: '';
    position: absolute;
    background: #444;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    width: 1px;
    height: calc(100% + 2px);
    top: 8px;
    right: -6px;
    opacity: 0.6;
  }
}

.ant-form-item-extra {
  color: #666;
  font-weight: 400;
  font-size: 13px;
}

.ant-form-item-explain {
  div {
    font-size: 12px;
    font-style: italic;
  }
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.3) !important;
  border-color: #d9d9d9 !important;
  background: #d9d9d9 !important;
  text-shadow: none;
  box-shadow: none;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #ffffff;
}

.ant-select-item-option-state {
  svg {
    color: green !important;
  }
}
