@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700;800;900&display=swap');

body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

button {
  font-family: 'Montserrat', sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Montserrat', sans-serif;
}

text.highcharts-title {
  font-family: 'Montserrat', sans-serif;
  color: #4178b2 !important;
  fill: #4178b1 !important;
  font-size: 18px;
  font-weight: 400;
}

.highcharts-container {
  text {
    font-family: 'Montserrat', sans-serif;
  }
}
