.ant-layout-sider {
  position: relative;
  min-width: 0;
  transition: all 0.2s;

  li.ant-menu-item {
    a {
      align-items: center;
      display: flex;
      flex-direction: row;
    }

    svg {
      margin-right: 6px;
      width: 18px;
    }
  }
}

.ant-layout {
  background: #f6f8fa;
  max-width: -webkit-fill-available;
  min-height: auto;
}

.site-card-wrapper {
  padding: 30px;
}

.ant-layout-sider li.ant-menu-item svg {
  margin-right: 0px !important;
  width: 22px !important;
  font-size: 22px !important;
}
